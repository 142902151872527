<template>
  <a-row :gutter="10" style="margin-top: 10px">
    <a-form :layout="formLayout" labelAlign="left" :form="searchForm" @submit="handleSubmit">
      <a-col :span="12">
        <a-form-item label="查阅时间" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-range-picker
              allowClear
              format="YYYY-MM-DD"
              v-decorator="['create_time']"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item :label-col="labelCol" >
          <a-button style="margin-right: 10px" type="primary" html-type="submit" icon="search">搜索</a-button>
          <a-button @click="handleReset">重置表单</a-button>
        </a-form-item>
      </a-col>
    </a-form>
  </a-row>
</template>
<script>
import {SelectRegion} from '@/components'

export default {
  name: 'searchForm',
  components: {
    SelectRegion
  },
  data() {
    return {
      searchForm: this.$form.createForm(this),
      queryParam: {},
      formLayout: 'horizontal',
      labelCol: {
        style: 'width: 70px;float:left;margin-right:10px;white-space: nowrap;\n' +
            'text-overflow: ellipsis;\n' +
            '-o-text-overflow: ellipsis;\n' +
            'overflow: hidden;'
      },
      wrapperCol: {style: 'width: calc( 95% - 70px );float:left'},

      //类型
      options: [
        {lable: '保养', value: 0},
        // {lable: '维修', value: 1},
        {lable: '其他', value: 2}
      ]
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      const {searchForm: {validateFields}} = this
      validateFields((errors, values) => {
        if (errors) {
          return false
        }
        this.$emit('handleSubmit', values)
      })
    },
    handleReset() {
      this.searchForm.resetFields()
    }
  }
}
</script>
<style scoped lang="less">

</style>
